/**
 * 实时猫错误信息
 * @prop {external:Integer} code 错误代号
 * @prop {external:String}  name 错误名
 * @prop {external:String}  message 详细错误
 */
class RTCatError extends Error {

  constructor
    ({
      code,
      name,
      message
    }) {
      super();

      this.code = code;
      // name is ocuppied by Error.prototype.name.
      this.eName = name || (RTCatErrorMessage[code] !== undefined ? RTCatErrorMessage[code].name : 'RTCatError');
      this.message = '[' + this.eName + '] ' + message || (RTCatErrorMessage[code] !== undefined ? RTCatErrorMessage[code].message : 'unkown');
    }
}


const ErrorCode = {

  // rpc error

  RPC_CLOSED: 0,
  PRC_INVALID_STATU: 1,
  RPC_INVALID_METHOD: 2,
  RPC_REQ_TIMEOUT: 3,


  // local error type code

  LOCAL_GENERA: 100,
  LOCAL_STREAM_NOT_INIT: 101,
  REMOTE_STREAM_NOT_RECEIVED: 102,
  STREAM_ALREADY_PLAYED: 103,
  STREAM_ALREADY_STOPPED: 104,
  LOCAL_STREAM_ACCEPTED_DENIES: 105,
  EXTENSION_NOT_EXISTED: 106,
  BROWSER_NOT_SUPPORTED: 107,


  // server error type code

  REMOTE_GENERA: 400,
  NOT_AVAILABLE: 401,
  NOT_EXIST: 402,
  ALREADY_EXIST: 403,
  NOT_READY: 404,
  INVALID_REQUEST: 405,
  NOT_ALLOWED: 406,
  TOKEN_INVALID: 407,

  INTERNAL_ERROR: 500,
  ROOT_DOWN: 501,
  ROUTER_DOWN: 502,
}

const Unknown = 'contact with BaiJiaYun for more information';

const RTCatErrorMessage = {

  // local RTCatErrorMessage
  [ErrorCode.LOCAL_GENERA]: {
    name: 'LOCAL-GENERA',
    message: Unknown
  },
  [ErrorCode.LOCAL_STREAM_NOT_INIT]: {
    name: 'LOCAL-STREAM-NOT-INIT',
    message: 'please call init() before do any operation!'
  },
  [ErrorCode.REMOTE_STREAM_NOT_RECEIVED]: {
    name: 'REMOTE-STREAM-NOT-RECEIVED',
    message: 'remote stream is empty,please try late.'
  },
  [ErrorCode.STREAM_ALREADY_PLAYED]: {
    name: 'STREAM-ALREADY-PLAYED',
    message: 'stream already played.'
  },
  [ErrorCode.STREAM_ALREADY_STOPPED]: {
    name: 'STREAM-ALREADY-STOPPED',
    message: 'stream already stopped.'
  },
  [ErrorCode.LOCAL_STREAM_ACCEPTED_DENIES]: {
    name: 'LOCAL-STREAM-ACCEPTED-DENIES',
    message: 'stream accepted denies.'
  },
  [ErrorCode.EXTENSION_NOT_EXISTED]: {
    name: 'EXTENSION-NOT-EXISTED',
    message: 'the extension is not existed.'
  },
  [ErrorCode.BROWSER_NOT_SUPPORTED]: {
    name: 'BROWSER-NOT-SUPPORTED',
    message: 'this browser is not supported by shishimao.'
  },

  // remote RTCatErrorMessage
  [ErrorCode.REMOTE_GENERA]: {
    name: 'REMOTE-GENERA',
    message: Unknown
  },
};

RTCatError.ErrorCode = ErrorCode;


module.exports = RTCatError;
