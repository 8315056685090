//require('webrtc-adapter');

/**
 * @class RTCat
 */

const RTCatLog = require('./utils/log');

const SdkInfo = require('./sdk_info');
const Devices = require('./rtc/devices');
const Detect = require('./platform/detect');
const codecTest = require('./platform/codec_test');
const LocalStream = require('./rtc/localstream');
const Utils = require('./utils/utils');
const RTCatErrorCode = require('./error').ErrorCode;
const RTCatEventCode = require('./event').EventCode;

const SignalServer = require('./server/signal');


const RTCat = {}

/**
 * get version
 * @return {string}
 */
RTCat.getVersion = function () {
  return SdkInfo.version;
}


RTCat.log = RTCatLog;

/**
 * 设置实时猫日志等级
 * @function RTCatLog.setLogLevel
 * @param {RTCatLog.LogLevels} logLevel 日志等级
 */
RTCat.LogLevels = RTCatLog.LogLevels;

RTCat.setLogLevel = function (logLevel) {
  RTCatLog.setLogLevel(logLevel);
}

// set default log level to info.
RTCat.setLogLevel(RTCat.LogLevels.INFO);

RTCat.SESSION_STATE = {
  DISCONNECTED: 0,
  CONNECTING: 1,
  CONNECTED: 2
}

RTCat.mediaDevices = Devices;

/**
 * 获得支持的摄像头设备
 * @function RTCat.getCameraDevices
 * @return {external:Promise} Array of media devices {@link external:MediaDeviceInfo}
 */
RTCat.getCameraDevices = Devices.getCameras

/**
 * 获得支持的音频输入设备
 * @function RTCat.getAudioInputDevices
 * @return {external:Promise} Array of media devices {@link external:MediaDeviceInfo}
 */
RTCat.getAudioInputDevices = Devices.getMics

/**
 * 获得支持的音频输出设备
 * @function RTCat.getAudioOutputDevices
 * @return {external:Promise} Array of media devices {@link external:MediaDeviceInfo}
 */
RTCat.getAudioOutputDevices = Devices.getSpeakers

/**
 * 获取支持的分辨率
 * @function RTCat.scanCamera
 * @param {string} deviceId
 * @param {Array} Array of resolutions
 * @return {external:Promise} Array of supported resolutions
 */
RTCat.scanCamera = Devices.scanCamera

/**
 * 检测浏览器 , 其中包括检测浏览器情况和本地网络等。 此方法只做检测使用,不影响音视频连接。
 * @function RTCat.detect
 * @param {external:Object} [options]
 * @param {external:Boolean} [options.browser=false] 是否检测浏览器类型
 * @param {external:Boolean} [options.compatibility=false] 是否检测兼容性
 * @param {external:Boolean} [options.network=false] 是否检测网络情况(检测网络需要一段时间)
 * @returns {external:Promise} 检测结果
 * ```
 *  {
 *    compatibility : {
 *      media(bool),
 *      data(bool),
 *      screen(bool)
 *    },
 *    network : {
 *      download(number,kb/s),
 *      upload(number,kb/s)
 *    },
 *    browser : {
 *      name(string) ,
 *      version(string)
 *    }
 *  }
 * ```
 */
RTCat.detect = Detect.all


/**
 *  * 创建本地流
 * @function RTCat.createStream
 * @param {external:Object} [options]
 * @param {RTCat.StreamType} [options.type=RTCat.StreamType.MediaDevice] 本地流的类型
 * @param {external:Object} [options.audio={(deviceId, enableEchoCancellation:true, enableNoiseSuppression:true, enableAutoGainControl:true) | constraints}]
 * @param {external:Object} [options.video={(deviceId, fps:15, size:{width:320, height:240}) | constraints}]
 * @param {external:Object} [options.external={(element, fps:10) | (src, muted:true, controls:true, loop:true, fps:10)}] 
 */
RTCat.createStream = async function (config) {
  let stream = new LocalStream(config);
  await stream.init();
  return stream;
}

/**
 * webrtc signal server
 * @param url 
 * @param tokenId
 * @param options
 */
RTCat.createSignalServer = function (url, tokenId, options = {}) {
  options.version = RTCat.getVersion();
  return new SignalServer(url, tokenId, options);
};


RTCat.checkAudio = async (curFile, timeout = 10000) => {
  return new Promise((y, n) => {

    let timer = setTimeout(_ => {
      y({
        'isSupported': false,
        'message': 'timeout'
      })
    }, timeout)

    let fileReader = new FileReader()
    fileReader.onload = async function (event) {
      try {
        let cx = Utils.getAudioContext()
        let decodedBuffer = await cx.decodeAudioData(event.target.result)
        let channels = decodedBuffer.numberOfChannels
        clearTimeout(timer)
        if (channels != 1 && channels != 2) {
          y({
            'isSupported': false,
            'message': `channels ${channels} no supported `
          })
        } else {
          y({
            'isSupported': true,
            'message': 'ok'
          })
        }
      } catch (e) {
        clearTimeout(timer)
        y({
          'isSupported': false,
          'message': e.message
        })
      }


    }
    fileReader.readAsArrayBuffer(curFile)

  })
}

/**
 * 流的类型
 * @readonly
 * @enum {external:Integer}
 * @property {external:Integer} MediaDevice 音视频流
 * @property {external:Integer} Screen 屏幕分享流
 * @property {external:Integer} HtmlElement HTML元素流
 * @property {external:Integer} File 文件流
 */
RTCat.StreamType = LocalStream.Type

RTCat.audioContext = Utils.getAudioContext()

RTCat.codecTest = codecTest

RTCat.ErrorCode = RTCatErrorCode;

RTCat.EventCode = RTCatEventCode


module.exports = RTCat;