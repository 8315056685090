const RTCatLog = require('../utils/log');
const Connection = require('../rtc/connection');
const SdpUtils = require('../utils/sdp_utils');


const DEFAULT_BANDWIDTH = 1000 // 1Mb , unit 1kb


function setAudio(sdpString, enableStereo) {
  let opus_config = {
    'opusStereo': enableStereo,
  };

  RTCatLog.I(`audio config : `, JSON.stringify(opus_config));
  return SdpUtils.maybeSetOpusOptions(sdpString, opus_config);
}

// function removeRemb(sdp) {
//   let sdp_str = sdp.sdp;
//   let nSdp = sdp_str.replace(new RegExp('a=rtcp-fb:96 goog-remb\r?\n', 'g'), '')

//   return {
//     type: 'offer',
//     sdp: nSdp
//   }
// }

/**
 * 订阅者，用于在SFU会话中订阅音视频流
 */
class Subscriber extends Connection {
  constructor({
    userId,
    userNumber,
    stun_servers,
    stream,
    options
  }) {
    super(userId, stun_servers, 'subscriber');

    this.unsub = false;

    this.generation = 0;

    this.userNumber = userNumber;

    this.stream = stream;

    this.audio = {
      stereo: false
    };

    this.video = {
      bandwidth: DEFAULT_BANDWIDTH * 3
    }

    this.cpuDetect = true;
    this.server = undefined;

    this._setUp(options);
  }

  _setUp(options) {
    const audioOpts = options.audio;
    const videoOpts = options.video;

    if (options && (typeof options === 'object')) {
      if (audioOpts && audioOpts.stereo) {
        this.audio.stereo = audioOpts.stereo;
      }

      if (videoOpts && videoOpts.bandwidth) {
        this.video.bandwidth = videoOpts.bandwidth;
      }

      if (options.cpuDetect !== undefined) {
        this.cpuDetect = options.cpuDetect;
      }

      if (options.server !== undefined) {
        this.server = options.server;
      }
    }

    this.muteMedia = {
      video: false,
      audio: false
    };

    const sp = options.switchParams; // eg: {X: 20, Y: 600, Z: 8}

    if (sp) {

      this.on('parsed-stats', stats => {

        this.statsHis.push(stats);

        if (stats.interframeDelayMax > sp.Y) {
          this.emit('freeze', stats.interframeDelayMax);
        }

        if (this.statsHis.length >= sp.X) { // every X s 
          let bad = 0;
          this.statsHis.forEach(s => {
            if (s.interframeDelayMax && s.interframeDelayMax > sp.Y) {
              bad++;
            }
          });

          if (bad > sp.Z) { // too bad, need to switch server
            this.emit('massive-freeze', bad);
          }

          this.statsHis = [];
        }
      });
    }

  }

  async subscribe({
    operate = undefined,
    reason = 'normal',
    timeout = 10
  }) {

    this.check();

    this.setupRtcPeer();

    let offer = await this._sub(timeout);

    const uuid = Math.round(Math.random() * 100000000); // 1/100 000 000 collision probability.

    this.generation++;

    return {
      uuid,
      feed: this.userId,
      sdp: offer.sdp,
      options: {
        bandwidth: this.video.bandwidth,
        server: this.server,
        operate, // switch-server
        reason
      }
    }
  }

  // local sdp handler
  _sdpHandler(offer) {
    let sdp = offer.sdp;

    sdp = SdpUtils.opusNACK(sdp);
    sdp = setAudio(sdp, this.audio.stereo);

    return {
      type: 'offer',
      sdp
    };
  }

  async _sub(timeout = 10) {
    this.setTimer(timeout);

    return this.peer.offer(null, null, sdp => {
      return this._sdpHandler(sdp);
    });
  }

  setupRtcPeer() {

    super.setupRtcPeer(this.cpuDetect);

    this.peer.ontrack = track => {

      if (this.stream && track) {

        if (track.kind === 'audio') {

          this.stream.audioTrack = track;

        } else if (track.kind === 'video') {

          this.stream.videoTrack = track;

        }
      }
    };

    this.statsHis = [];
  }
}

module.exports = Subscriber;